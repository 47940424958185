/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

/**
 * Enum for activity category link section IDs
 */
export enum ActivityCategoryLinkSectionId {
  ACADEMIC = "ACADEMIC",
  ADVENTURE = "ADVENTURE",
  ART = "ART",
  DANCE = "DANCE",
  MUSIC = "MUSIC",
  OUTDOOR_AND_ADVENTURE = "OUTDOOR_AND_ADVENTURE",
  SPORTS = "SPORTS",
  STEM = "STEM",
}

/**
 * How a person will participate in an activity
 */
export enum ActivityParticipationType {
  IN_PERSON = "IN_PERSON",
  ONLINE = "ONLINE",
}

/**
 * Availability Filter Options
 */
export enum AvailabilityList {
  FULL_TIME = "FULL_TIME",
  PART_TIME = "PART_TIME",
}

/**
 * Background check types
 */
export enum BackgroundCheckType {
  CARE_CHECK = "CARE_CHECK",
  CONTINUOUS_BGC = "CONTINUOUS_BGC",
  CRIMINAL_CHECK = "CRIMINAL_CHECK",
  MVR_CHECK = "MVR_CHECK",
  PREMIER_CHECK = "PREMIER_CHECK",
}

/**
 * Type of badges displayed on a Caregiver's profile
 */
export enum CaregiverBadge {
  BACKGROUND_CHECK = "BACKGROUND_CHECK",
  PREMIUM = "PREMIUM",
  VACCINATED = "VACCINATED",
}

/**
 * A caregivers verified services and communication channels
 */
export enum CaregiverVerifications {
  EMAIL_ID_VERIFIED = "EMAIL_ID_VERIFIED",
  FACEBOOK_USER_LINKED = "FACEBOOK_USER_LINKED",
  PHONE_NUMBER_VERIFIED = "PHONE_NUMBER_VERIFIED",
}

/**
 * # Age groups child care caregiver can serve 
 */
export enum ChildCareAgeGroups {
  EARLY_SCHOOL = "EARLY_SCHOOL",
  ELEMENTARY_SCHOOL = "ELEMENTARY_SCHOOL",
  NEWBORN = "NEWBORN",
  TEEN = "TEEN",
  TODDLER = "TODDLER",
}

/**
 * A Cost guide percentage can be one of these
 */
export enum CostGuidePercentageRelativePosition {
  HIGHER = "HIGHER",
  LOWER = "LOWER",
}

/**
 * Enum representing the days of the week
 */
export enum DayOfWeek {
  FRIDAY = "FRIDAY",
  MONDAY = "MONDAY",
  SATURDAY = "SATURDAY",
  SUNDAY = "SUNDAY",
  THURSDAY = "THURSDAY",
  TUESDAY = "TUESDAY",
  WEDNESDAY = "WEDNESDAY",
}

/**
 * Measures of distance
 */
export enum DistanceUnit {
  KILOMETERS = "KILOMETERS",
  MILES = "MILES",
}

/**
 * Education level of provider
 */
export enum EducationLevel {
  COLLEGE = "COLLEGE",
  COLLEGE_DEGREE = "COLLEGE_DEGREE",
  GED = "GED",
  GRADUATE = "GRADUATE",
  GRADUATE_DEGREE = "GRADUATE_DEGREE",
  HIGH_SCHOOL = "HIGH_SCHOOL",
  HIGH_SCHOOL_DEGREE = "HIGH_SCHOOL_DEGREE",
  HIGH_SCHOOL_DIPLOMA = "HIGH_SCHOOL_DIPLOMA",
  SOME_COLLEGE = "SOME_COLLEGE",
  SOME_GRADUATE_SCHOOL = "SOME_GRADUATE_SCHOOL",
  SOME_HIGH_SCHOOL = "SOME_HIGH_SCHOOL",
}

/**
 * The gender of an individual
 */
export enum Gender {
  FEMALE = "FEMALE",
  MALE = "MALE",
}

/**
 * Housekeeping services
 */
export enum HousekeepingService {
  ATTIC_CLEANING = "ATTIC_CLEANING",
  BASEMENT_CLEANING = "BASEMENT_CLEANING",
  BATHROOM_CLEANING = "BATHROOM_CLEANING",
  CABINET_CLEANING = "CABINET_CLEANING",
  CARPET_CLEANING = "CARPET_CLEANING",
  CHANGING_BED_LINENS = "CHANGING_BED_LINENS",
  DEEP_CLEANING = "DEEP_CLEANING",
  DISHES = "DISHES",
  DUSTING = "DUSTING",
  FURNITURE_TREATMENT = "FURNITURE_TREATMENT",
  GENERAL_ROOM_CLEANING = "GENERAL_ROOM_CLEANING",
  HOUSE_SITTING = "HOUSE_SITTING",
  KITCHEN_CLEANING = "KITCHEN_CLEANING",
  LAUNDRY = "LAUNDRY",
  MOVE_OUT_CLEANING = "MOVE_OUT_CLEANING",
  ORGANIZATION = "ORGANIZATION",
  OVEN_CLEANING = "OVEN_CLEANING",
  PACKING_AND_UNPACKING = "PACKING_AND_UNPACKING",
  PET_WASTE_CLEANUP = "PET_WASTE_CLEANUP",
  PLANT_CARE = "PLANT_CARE",
  REFRIGERATOR_CLEANING = "REFRIGERATOR_CLEANING",
  STANDARD_CLEANING = "STANDARD_CLEANING",
  SURFACE_POLISHING = "SURFACE_POLISHING",
  VACUUMING_MOPPING = "VACUUMING_MOPPING",
  WALL_WASHING = "WALL_WASHING",
  WINDOW_WASHING = "WINDOW_WASHING",
}

/**
 * Accepted values for Internal Link section.
 */
export enum InternalLinkSectionId {
  ARTICLES = "ARTICLES",
  JOBS = "JOBS",
  RELATED_SERVICES = "RELATED_SERVICES",
  RESOURCES = "RESOURCES",
  TOP_CITIES = "TOP_CITIES",
}

/**
 * Type of a job posted by a seeker
 */
export enum JobType {
  BACKUP_CARE = "BACKUP_CARE",
  FULL_TIME = "FULL_TIME",
  IB_RECURRING_CARE = "IB_RECURRING_CARE",
  ONE_TIME = "ONE_TIME",
  ONE_TIME_CARE = "ONE_TIME_CARE",
  PART_TIME = "PART_TIME",
}

/**
 * Languages currently supported by the native app
 */
export enum Language {
  AFRIKAANS = "AFRIKAANS",
  ALBANIAN = "ALBANIAN",
  ARABIC = "ARABIC",
  ARMENIAN = "ARMENIAN",
  AZERBAIJANI = "AZERBAIJANI",
  BELARUSIAN = "BELARUSIAN",
  BENGALI = "BENGALI",
  BOSNIAN = "BOSNIAN",
  BULGARIAN = "BULGARIAN",
  CAMBODIAN = "CAMBODIAN",
  CATALAN = "CATALAN",
  CHINESE = "CHINESE",
  CROATIAN = "CROATIAN",
  CZECH = "CZECH",
  DANISH = "DANISH",
  DUTCH = "DUTCH",
  ENGLISH = "ENGLISH",
  ESTONIAN = "ESTONIAN",
  FINNISH = "FINNISH",
  FRENCH = "FRENCH",
  GEORGIAN = "GEORGIAN",
  GERMAN = "GERMAN",
  GREEK = "GREEK",
  HEBREW = "HEBREW",
  HINDI = "HINDI",
  HUNGARIAN = "HUNGARIAN",
  ICELANDIC = "ICELANDIC",
  INDONESIAN = "INDONESIAN",
  ITALIAN = "ITALIAN",
  JAPANESE = "JAPANESE",
  KAZAKH = "KAZAKH",
  KOREAN = "KOREAN",
  KURDISH = "KURDISH",
  LAOTIAN = "LAOTIAN",
  LATVIAN = "LATVIAN",
  LITHUANIAN = "LITHUANIAN",
  MADARIN = "MADARIN",
  MALAY = "MALAY",
  MANDARIN = "MANDARIN",
  MOLDAVIAN = "MOLDAVIAN",
  MONGOLIAN = "MONGOLIAN",
  NEPALI = "NEPALI",
  NORWEGIAN = "NORWEGIAN",
  PERSIAN = "PERSIAN",
  POLISH = "POLISH",
  PORTUGUESE = "PORTUGUESE",
  PORTUGUESE_BRAZIL = "PORTUGUESE_BRAZIL",
  PUNJABI = "PUNJABI",
  ROMANIAN = "ROMANIAN",
  RUSSIAN = "RUSSIAN",
  SERBIAN = "SERBIAN",
  SERBO_CROATIAN = "SERBO_CROATIAN",
  SESOTHO = "SESOTHO",
  SIGN_ASL = "SIGN_ASL",
  SIGN_LANGUAGE_ASL = "SIGN_LANGUAGE_ASL",
  SIGN_LANGUAGE_SEE = "SIGN_LANGUAGE_SEE",
  SIGN_SEE = "SIGN_SEE",
  SINDHI = "SINDHI",
  SLOVAK = "SLOVAK",
  SLOVENIAN = "SLOVENIAN",
  SOMALI = "SOMALI",
  SPANISH = "SPANISH",
  SUDANESE = "SUDANESE",
  SWAHILI = "SWAHILI",
  SWEDISH = "SWEDISH",
  TAGALOG = "TAGALOG",
  TAJIK = "TAJIK",
  TAMIL = "TAMIL",
  TELUGU = "TELUGU",
  THAI = "THAI",
  TONGA = "TONGA",
  TURKISH = "TURKISH",
  UKRAINIAN = "UKRAINIAN",
  UKRANIAN = "UKRANIAN",
  URDU = "URDU",
  UZBEK = "UZBEK",
  VIETNAMESE = "VIETNAMESE",
  YIDDISH = "YIDDISH",
  ZULU = "ZULU",
}

/**
 * Represents the care types for a review.
 */
export enum ReviewInfoCareType {
  AU_PAIR = "AU_PAIR",
  CHILD_CARE = "CHILD_CARE",
  ERRANDS = "ERRANDS",
  GENERIC = "GENERIC",
  HOUSEKEEPING = "HOUSEKEEPING",
  PET_CARE = "PET_CARE",
  SENIOR_CARE = "SENIOR_CARE",
  SPECIAL_NEEDS = "SPECIAL_NEEDS",
  TUTORING = "TUTORING",
}

/**
 * Entity types for reviews.
 */
export enum ReviewInfoEntityType {
  PROVIDER = "PROVIDER",
  SEEKER = "SEEKER",
  UNKNOWN = "UNKNOWN",
}

/**
 * Represents the type of star ratings a review can contain.
 */
export enum ReviewInfoRatingType {
  ACCURATE_CALENDAR = "ACCURATE_CALENDAR",
  ACTIVITIES = "ACTIVITIES",
  CLEANLINESS = "CLEANLINESS",
  COMMUNICATION = "COMMUNICATION",
  DEPENDABILITY = "DEPENDABILITY",
  EXPERTISE = "EXPERTISE",
  FACILITIES = "FACILITIES",
  GOOD_WITH_MULTIPLE_KIDS = "GOOD_WITH_MULTIPLE_KIDS",
  GOOD_WITH_MULTIPLE_PETS = "GOOD_WITH_MULTIPLE_PETS",
  KNOWLEDGEABLE = "KNOWLEDGEABLE",
  OVERALL = "OVERALL",
  PROFESSIONALISM = "PROFESSIONALISM",
  PUNCTUALITY = "PUNCTUALITY",
  QUALITY_OF_CARE = "QUALITY_OF_CARE",
  QUALITY_OF_LESSON = "QUALITY_OF_LESSON",
  REHIRE = "REHIRE",
  RELIABLE_TRANSPORTATION = "RELIABLE_TRANSPORTATION",
  RESPONSIVENESS = "RESPONSIVENESS",
  SKILL_SET = "SKILL_SET",
}

/**
 * Represents a possible source of an author of a review.
 */
export enum ReviewInfoReviewerSource {
  CZEN = "CZEN",
  GALORE = "GALORE",
  INTERNATIONAL = "INTERNATIONAL",
}

/**
 * Review statuses for reviews.
 */
export enum ReviewInfoStatus {
  APPROVED = "APPROVED",
  APPROVED_SAFETY_CONCERN = "APPROVED_SAFETY_CONCERN",
  DRAFT = "DRAFT",
  PENDING_APPROVAL = "PENDING_APPROVAL",
  PENDING_APPROVAL_SAFETY_CONCERN = "PENDING_APPROVAL_SAFETY_CONCERN",
  PENDING_AUDIT = "PENDING_AUDIT",
  REJECTED_AGENCY_AS_INDIVIDUAL = "REJECTED_AGENCY_AS_INDIVIDUAL",
  REJECTED_INSUFFICIENT = "REJECTED_INSUFFICIENT",
  REJECTED_OTHER = "REJECTED_OTHER",
  REVIEWED = "REVIEWED",
  REVIEWED_SAFETY_CONCERN = "REVIEWED_SAFETY_CONCERN",
}

/**
 * Qualifications that belong to Child Care vertical
 */
export enum SEOChildCareQualifications {
  CERTIFIED_NURSING_ASSISTANT = "CERTIFIED_NURSING_ASSISTANT",
  CERTIFIED_REGISTERED_NURSE = "CERTIFIED_REGISTERED_NURSE",
  CERTIFIED_TEACHER = "CERTIFIED_TEACHER",
  CHILD_DEVELOPMENT_ASSOCIATE_CDA = "CHILD_DEVELOPMENT_ASSOCIATE_CDA",
  CPR_TRAINING = "CPR_TRAINING",
  CRN = "CRN",
  DOULA = "DOULA",
  EARLY_CHILDHOOD_EDUCATION_ECE = "EARLY_CHILDHOOD_EDUCATION_ECE",
  EARLY_CHILD_DEVELOPMENT_COURSEWORK = "EARLY_CHILD_DEVELOPMENT_COURSEWORK",
  FIRST_AID_TRAINING = "FIRST_AID_TRAINING",
  NAFCC_CERTIFIED = "NAFCC_CERTIFIED",
  SPECIAL_NEEDS_CARE = "SPECIAL_NEEDS_CARE",
  TRUSTLINE_CERTIFIED_CALIFORNIA = "TRUSTLINE_CERTIFIED_CALIFORNIA",
}

/**
 * Accepted values for the seo search result job type filter
 */
export enum SEOSegmentationJobTypeFilter {
  ANY = "ANY",
  FULL_TIME = "FULL_TIME",
  ONE_TIME = "ONE_TIME",
  PART_TIME = "PART_TIME",
}

/**
 * Segmentation page can either be national (/child-care) or
 * local (/child-care/austin-tx)
 */
export enum SEOSegmentationPageType {
  BRAND = "BRAND",
  COLLEGE = "COLLEGE",
  LOCAL = "LOCAL",
  NATIONAL = "NATIONAL",
}

/**
 * Duration abbreviation for rates
 */
export enum SEOSegmentationRateDuration {
  DAILY = "DAILY",
  FLAT = "FLAT",
  HOURLY = "HOURLY",
  MONTHLY = "MONTHLY",
  NIGHTLY = "NIGHTLY",
  PER_SESSION = "PER_SESSION",
  WEEKLY = "WEEKLY",
}

/**
 * Available filters for a segmentation listing page
 */
export enum SEOSegmentationSearchFilters {
  AGE = "AGE",
  AVAILABILITY = "AVAILABILITY",
  DISTANCE = "DISTANCE",
  EXPERIENCE_WITH_AGES = "EXPERIENCE_WITH_AGES",
  HOURS = "HOURS",
  JOB_TYPE = "JOB_TYPE",
  PAY_RATE = "PAY_RATE",
  SCHEDULE = "SCHEDULE",
  SERVICES = "SERVICES",
  SUBJECT_AREA = "SUBJECT_AREA",
  YEARS_OF_EXPERIENCE = "YEARS_OF_EXPERIENCE",
}

/**
 * Type of segmentation search results
 */
export enum SEOSegmentationSearchingFor {
  ACTIVITIES = "ACTIVITIES",
  ALL_JOB = "ALL_JOB",
  ALL_PROVIDER = "ALL_PROVIDER",
  NANNY_SHARE = "NANNY_SHARE",
  PROVIDER = "PROVIDER",
  SEEKER_JOB = "SEEKER_JOB",
  SMB_JOB = "SMB_JOB",
  SMB_PROVIDER = "SMB_PROVIDER",
}

/**
 * Subservices that belong to Senior Care vertical
 */
export enum SEOSeniorCareSubServices {
  BATHING_DRESSING = "BATHING_DRESSING",
  COMPANIONSHIP = "COMPANIONSHIP",
  DEMENTIA = "DEMENTIA",
  ERRANDS_SHOPPING = "ERRANDS_SHOPPING",
  FEEDING = "FEEDING",
  HEAVY_LIFTING = "HEAVY_LIFTING",
  HELP_STAYING_ACTIVE = "HELP_STAYING_ACTIVE",
  HOME_HEALTH = "HOME_HEALTH",
  HOME_MODIFICATION = "HOME_MODIFICATION",
  HOSPICE_SERVICES = "HOSPICE_SERVICES",
  LIGHT_HOUSECLEANING = "LIGHT_HOUSECLEANING",
  LIVE_IN_HOME = "LIVE_IN_HOME",
  MEAL_PREPARATION = "MEAL_PREPARATION",
  MEDICAL_MANAGEMENT = "MEDICAL_MANAGEMENT",
  MEDICAL_TRANSPORTATION = "MEDICAL_TRANSPORTATION",
  MOBILITY_ASSISTANCE = "MOBILITY_ASSISTANCE",
  PERSONAL_CARE = "PERSONAL_CARE",
  RESPITE_CARE = "RESPITE_CARE",
  SPECIALIZED_CARE = "SPECIALIZED_CARE",
  SPECIAL_NEEDS = "SPECIAL_NEEDS",
  TRANSPORTATION = "TRANSPORTATION",
  VISITING_NURSE = "VISITING_NURSE",
  VISITING_PHYSICIAN = "VISITING_PHYSICIAN",
}

/**
 * SEOServiceIdForMember represents is a broader level of categorization for a particular segmentation, which may or may not also be the serviceId depending on how many serviceIds a segmentation belongs too.
 */
export enum ServiceIdForMember {
  ACADEMIC = "ACADEMIC",
  ART = "ART",
  BABYSITTER = "BABYSITTER",
  CAMPS_LOCATION = "CAMPS_LOCATION",
  CARE_GIGS = "CARE_GIGS",
  CARE_GIGS_JOB = "CARE_GIGS_JOB",
  CHILD_CARE = "CHILD_CARE",
  CHILD_CARE_AFTER_SCHOOL_CARE = "CHILD_CARE_AFTER_SCHOOL_CARE",
  CHILD_CARE_JOB = "CHILD_CARE_JOB",
  CHILD_CARE_LOCATION = "CHILD_CARE_LOCATION",
  DANCE = "DANCE",
  FAMILY_CHILD_CARE = "FAMILY_CHILD_CARE",
  HOME_CARE_LOCATION = "HOME_CARE_LOCATION",
  HOUSEKEEPING = "HOUSEKEEPING",
  HOUSEKEEPING_JOB = "HOUSEKEEPING_JOB",
  HOUSING_LOCATION = "HOUSING_LOCATION",
  MUSIC = "MUSIC",
  NANNY = "NANNY",
  NANNY_SHARE = "NANNY_SHARE",
  OUTDOOR_AND_ADVENTURE = "OUTDOOR_AND_ADVENTURE",
  PET_CARE = "PET_CARE",
  PET_CARE_JOB = "PET_CARE_JOB",
  PET_DAY_CARE_LOCATION = "PET_DAY_CARE_LOCATION",
  PET_SITTING_WALKING = "PET_SITTING_WALKING",
  SENIOR_CARE = "SENIOR_CARE",
  SENIOR_CARE_JOB = "SENIOR_CARE_JOB",
  SPECIAL_NEEDS = "SPECIAL_NEEDS",
  SPECIAL_NEEDS_JOB = "SPECIAL_NEEDS_JOB",
  SPORTS = "SPORTS",
  STEM = "STEM",
  SUMMER_CHILD_CARE_JOB = "SUMMER_CHILD_CARE_JOB",
  TUTORING = "TUTORING",
  TUTORING_AFTER_SCHOOL_CARE = "TUTORING_AFTER_SCHOOL_CARE",
  TUTORING_JOB = "TUTORING_JOB",
}

/**
 * type of service provided by the caregiver
 * will be adding more services as required
 */
export enum ServiceType {
  AU_PAIR = "AU_PAIR",
  CARE_GIGS = "CARE_GIGS",
  CHILD_CARE = "CHILD_CARE",
  GENERIC = "GENERIC",
  HOUSEKEEPING = "HOUSEKEEPING",
  PET_CARE = "PET_CARE",
  SENIOR_CARE = "SENIOR_CARE",
  SPECIAL_NEEDS = "SPECIAL_NEEDS",
  TUTORING = "TUTORING",
}

/**
 * Type of sub service for service provided
 * 
 * @deprecated in favor of vertical-specific enums i.e. ChildcareSubtype, PetCareSubtype, etc
 */
export enum SubServiceType {
  BABYSITTER = "BABYSITTER",
  CAMPS = "CAMPS",
  CHILDCARE = "CHILDCARE",
  CHILDMINDER = "CHILDMINDER",
  DAY_CARE = "DAY_CARE",
  FAMILY_CARE = "FAMILY_CARE",
  IN_FACILITY = "IN_FACILITY",
  IN_HOME = "IN_HOME",
  NANNY = "NANNY",
  NANNY_OR_BABYSITTER = "NANNY_OR_BABYSITTER",
  NANNY_SHARE = "NANNY_SHARE",
  NOT_SURE = "NOT_SURE",
  ONE_TIME_BABYSITTER = "ONE_TIME_BABYSITTER",
}

/**
 * Specific Subject Types for Tutoring
 */
export enum TutoringSpecificSubjectType {
  ART_SUBJECT_ART_HISTORY = "ART_SUBJECT_ART_HISTORY",
  ART_SUBJECT_DRAWING = "ART_SUBJECT_DRAWING",
  ART_SUBJECT_PAINTING = "ART_SUBJECT_PAINTING",
  ART_SUBJECT_PHOTOGRAPHY = "ART_SUBJECT_PHOTOGRAPHY",
  BUSINESS_SUBJECT_ACCOUNTING = "BUSINESS_SUBJECT_ACCOUNTING",
  BUSINESS_SUBJECT_ECONOMICS = "BUSINESS_SUBJECT_ECONOMICS",
  BUSINESS_SUBJECT_FINANCE = "BUSINESS_SUBJECT_FINANCE",
  BUSINESS_SUBJECT_MARKETING = "BUSINESS_SUBJECT_MARKETING",
  BUSINESS_SUBJECT_ORGANIZATIONAL_BEHAVIOR = "BUSINESS_SUBJECT_ORGANIZATIONAL_BEHAVIOR",
  COMPUTERS_SUBJECT_ANDROID_APP_DEVELOPMENT = "COMPUTERS_SUBJECT_ANDROID_APP_DEVELOPMENT",
  COMPUTERS_SUBJECT_BASIC = "COMPUTERS_SUBJECT_BASIC",
  COMPUTERS_SUBJECT_C = "COMPUTERS_SUBJECT_C",
  COMPUTERS_SUBJECT_COMPUTER_BASICS = "COMPUTERS_SUBJECT_COMPUTER_BASICS",
  COMPUTERS_SUBJECT_COMPUTER_SCIENCE = "COMPUTERS_SUBJECT_COMPUTER_SCIENCE",
  COMPUTERS_SUBJECT_CPP = "COMPUTERS_SUBJECT_CPP",
  COMPUTERS_SUBJECT_HTML = "COMPUTERS_SUBJECT_HTML",
  COMPUTERS_SUBJECT_INTERNET_SITE_DESIGN = "COMPUTERS_SUBJECT_INTERNET_SITE_DESIGN",
  COMPUTERS_SUBJECT_IOS_APP_DEVELOPMENT = "COMPUTERS_SUBJECT_IOS_APP_DEVELOPMENT",
  COMPUTERS_SUBJECT_JAVA = "COMPUTERS_SUBJECT_JAVA",
  COMPUTERS_SUBJECT_JAVASCRIPT = "COMPUTERS_SUBJECT_JAVASCRIPT",
  COMPUTERS_SUBJECT_LINUX = "COMPUTERS_SUBJECT_LINUX",
  COMPUTERS_SUBJECT_MACINTOSH = "COMPUTERS_SUBJECT_MACINTOSH",
  COMPUTERS_SUBJECT_MICROSOFT_ACCESS = "COMPUTERS_SUBJECT_MICROSOFT_ACCESS",
  COMPUTERS_SUBJECT_MICROSOFT_EXCEL = "COMPUTERS_SUBJECT_MICROSOFT_EXCEL",
  COMPUTERS_SUBJECT_MICROSOFT_OFFICE = "COMPUTERS_SUBJECT_MICROSOFT_OFFICE",
  COMPUTERS_SUBJECT_MICROSOFT_POWERPOINT = "COMPUTERS_SUBJECT_MICROSOFT_POWERPOINT",
  COMPUTERS_SUBJECT_MICROSOFT_WINDOWS = "COMPUTERS_SUBJECT_MICROSOFT_WINDOWS",
  COMPUTERS_SUBJECT_MICROSOFT_WORD = "COMPUTERS_SUBJECT_MICROSOFT_WORD",
  COMPUTERS_SUBJECT_OUTLOOK = "COMPUTERS_SUBJECT_OUTLOOK",
  COMPUTERS_SUBJECT_PHOTOSHOP = "COMPUTERS_SUBJECT_PHOTOSHOP",
  COMPUTERS_SUBJECT_PROGRAMMING = "COMPUTERS_SUBJECT_PROGRAMMING",
  COMPUTERS_SUBJECT_PYTHON = "COMPUTERS_SUBJECT_PYTHON",
  COMPUTERS_SUBJECT_VISUAL_BASIC = "COMPUTERS_SUBJECT_VISUAL_BASIC",
  COMPUTERS_SUBJECT_WINDOWS = "COMPUTERS_SUBJECT_WINDOWS",
  DANCE_SUBJECT_BALLET = "DANCE_SUBJECT_BALLET",
  DANCE_SUBJECT_HIP_HOP = "DANCE_SUBJECT_HIP_HOP",
  DANCE_SUBJECT_JAZZ = "DANCE_SUBJECT_JAZZ",
  ENGLISH_SUBJECT_ESL = "ENGLISH_SUBJECT_ESL",
  ENGLISH_SUBJECT_GRAMMAR = "ENGLISH_SUBJECT_GRAMMAR",
  ENGLISH_SUBJECT_LANGUAGE_ARTS_K6 = "ENGLISH_SUBJECT_LANGUAGE_ARTS_K6",
  ENGLISH_SUBJECT_LITERATURE = "ENGLISH_SUBJECT_LITERATURE",
  ENGLISH_SUBJECT_READING = "ENGLISH_SUBJECT_READING",
  ENGLISH_SUBJECT_WRITING = "ENGLISH_SUBJECT_WRITING",
  FOREIGN_LANGUAGE_SUBJECT_ARABIC = "FOREIGN_LANGUAGE_SUBJECT_ARABIC",
  FOREIGN_LANGUAGE_SUBJECT_CHINESE = "FOREIGN_LANGUAGE_SUBJECT_CHINESE",
  FOREIGN_LANGUAGE_SUBJECT_FRENCH = "FOREIGN_LANGUAGE_SUBJECT_FRENCH",
  FOREIGN_LANGUAGE_SUBJECT_GERMAN = "FOREIGN_LANGUAGE_SUBJECT_GERMAN",
  FOREIGN_LANGUAGE_SUBJECT_HINDI = "FOREIGN_LANGUAGE_SUBJECT_HINDI",
  FOREIGN_LANGUAGE_SUBJECT_ITALIAN = "FOREIGN_LANGUAGE_SUBJECT_ITALIAN",
  FOREIGN_LANGUAGE_SUBJECT_JAPANESE = "FOREIGN_LANGUAGE_SUBJECT_JAPANESE",
  FOREIGN_LANGUAGE_SUBJECT_LATIN = "FOREIGN_LANGUAGE_SUBJECT_LATIN",
  FOREIGN_LANGUAGE_SUBJECT_PORTUGUESE = "FOREIGN_LANGUAGE_SUBJECT_PORTUGUESE",
  FOREIGN_LANGUAGE_SUBJECT_RUSSIAN = "FOREIGN_LANGUAGE_SUBJECT_RUSSIAN",
  FOREIGN_LANGUAGE_SUBJECT_SPANISH = "FOREIGN_LANGUAGE_SUBJECT_SPANISH",
  MATH_SUBJECT_ALGEBRA = "MATH_SUBJECT_ALGEBRA",
  MATH_SUBJECT_CALCULUS = "MATH_SUBJECT_CALCULUS",
  MATH_SUBJECT_ELEMENTARY_SCHOOL_MATH = "MATH_SUBJECT_ELEMENTARY_SCHOOL_MATH",
  MATH_SUBJECT_GEOMETRY = "MATH_SUBJECT_GEOMETRY",
  MATH_SUBJECT_HIGH_SCHOOL_MATH = "MATH_SUBJECT_HIGH_SCHOOL_MATH",
  MATH_SUBJECT_MIDDLE_SCHOOL_MATH = "MATH_SUBJECT_MIDDLE_SCHOOL_MATH",
  MATH_SUBJECT_PRE_CALCULUS = "MATH_SUBJECT_PRE_CALCULUS",
  MUSICAL_INSTRUMENTS_SUBJECT_CLARINET = "MUSICAL_INSTRUMENTS_SUBJECT_CLARINET",
  MUSICAL_INSTRUMENTS_SUBJECT_DRUMS = "MUSICAL_INSTRUMENTS_SUBJECT_DRUMS",
  MUSICAL_INSTRUMENTS_SUBJECT_FLUTE = "MUSICAL_INSTRUMENTS_SUBJECT_FLUTE",
  MUSICAL_INSTRUMENTS_SUBJECT_GUITAR = "MUSICAL_INSTRUMENTS_SUBJECT_GUITAR",
  MUSICAL_INSTRUMENTS_SUBJECT_KEYBOARD = "MUSICAL_INSTRUMENTS_SUBJECT_KEYBOARD",
  MUSICAL_INSTRUMENTS_SUBJECT_PIANO = "MUSICAL_INSTRUMENTS_SUBJECT_PIANO",
  MUSICAL_INSTRUMENTS_SUBJECT_SAXOPHONE = "MUSICAL_INSTRUMENTS_SUBJECT_SAXOPHONE",
  MUSICAL_INSTRUMENTS_SUBJECT_TRUMPET = "MUSICAL_INSTRUMENTS_SUBJECT_TRUMPET",
  MUSICAL_INSTRUMENTS_SUBJECT_VIOLIN = "MUSICAL_INSTRUMENTS_SUBJECT_VIOLIN",
  MUSIC_DRAMA_SUBJECT_DRAMA_THEATER = "MUSIC_DRAMA_SUBJECT_DRAMA_THEATER",
  MUSIC_DRAMA_SUBJECT_GENERAL_MUSIC = "MUSIC_DRAMA_SUBJECT_GENERAL_MUSIC",
  MUSIC_DRAMA_SUBJECT_SINGING = "MUSIC_DRAMA_SUBJECT_SINGING",
  SCIENCE_SUBJECT_BIOLOGY = "SCIENCE_SUBJECT_BIOLOGY",
  SCIENCE_SUBJECT_CHEMISTRY = "SCIENCE_SUBJECT_CHEMISTRY",
  SCIENCE_SUBJECT_EARTH_SCIENCE = "SCIENCE_SUBJECT_EARTH_SCIENCE",
  SCIENCE_SUBJECT_ELEMENTARY_SCIENCE = "SCIENCE_SUBJECT_ELEMENTARY_SCIENCE",
  SCIENCE_SUBJECT_LIFE_SCIENCE = "SCIENCE_SUBJECT_LIFE_SCIENCE",
  SCIENCE_SUBJECT_MIDDLE_SCHOOL = "SCIENCE_SUBJECT_MIDDLE_SCHOOL",
  SCIENCE_SUBJECT_PHYSICS = "SCIENCE_SUBJECT_PHYSICS",
  SCIENCE_SUBJECT_PSYCHOLOGY = "SCIENCE_SUBJECT_PSYCHOLOGY",
  SPECIAL_EDUCATION_SUBJECT_ADD_ADHD = "SPECIAL_EDUCATION_SUBJECT_ADD_ADHD",
  SPECIAL_EDUCATION_SUBJECT_DYSLEXIA = "SPECIAL_EDUCATION_SUBJECT_DYSLEXIA",
  SPECIAL_EDUCATION_SUBJECT_EMOTIONAL_BEHAVIOR = "SPECIAL_EDUCATION_SUBJECT_EMOTIONAL_BEHAVIOR",
  SPECIAL_EDUCATION_SUBJECT_HEARING_IMPAIRED = "SPECIAL_EDUCATION_SUBJECT_HEARING_IMPAIRED",
  SPECIAL_EDUCATION_SUBJECT_LEARNING_DISABLED = "SPECIAL_EDUCATION_SUBJECT_LEARNING_DISABLED",
  SPECIAL_EDUCATION_SUBJECT_MILDLY_HANDICAPPED = "SPECIAL_EDUCATION_SUBJECT_MILDLY_HANDICAPPED",
  SPORTS_FITNESS_SUBJECT_BASEBALL = "SPORTS_FITNESS_SUBJECT_BASEBALL",
  SPORTS_FITNESS_SUBJECT_BASKETBALL = "SPORTS_FITNESS_SUBJECT_BASKETBALL",
  SPORTS_FITNESS_SUBJECT_FIELD_HOCKEY = "SPORTS_FITNESS_SUBJECT_FIELD_HOCKEY",
  SPORTS_FITNESS_SUBJECT_FOOTBALL = "SPORTS_FITNESS_SUBJECT_FOOTBALL",
  SPORTS_FITNESS_SUBJECT_GYMNASTICS = "SPORTS_FITNESS_SUBJECT_GYMNASTICS",
  SPORTS_FITNESS_SUBJECT_PERSONAL_TRAINING = "SPORTS_FITNESS_SUBJECT_PERSONAL_TRAINING",
  SPORTS_FITNESS_SUBJECT_RUNNING = "SPORTS_FITNESS_SUBJECT_RUNNING",
  SPORTS_FITNESS_SUBJECT_SOCCER = "SPORTS_FITNESS_SUBJECT_SOCCER",
  SPORTS_FITNESS_SUBJECT_SOFTBALL = "SPORTS_FITNESS_SUBJECT_SOFTBALL",
  SPORTS_FITNESS_SUBJECT_SWIMMING = "SPORTS_FITNESS_SUBJECT_SWIMMING",
  SPORTS_FITNESS_SUBJECT_TENNIS = "SPORTS_FITNESS_SUBJECT_TENNIS",
  SPORTS_FITNESS_SUBJECT_TRACK_AND_FIELD = "SPORTS_FITNESS_SUBJECT_TRACK_AND_FIELD",
  SPORTS_FITNESS_SUBJECT_UNNING = "SPORTS_FITNESS_SUBJECT_UNNING",
  SPORTS_FITNESS_SUBJECT_VOLLEYBALL = "SPORTS_FITNESS_SUBJECT_VOLLEYBALL",
  TEST_PREP_SUBJECT_3RD_GRADE_SOL = "TEST_PREP_SUBJECT_3RD_GRADE_SOL",
  TEST_PREP_SUBJECT_5TH_GRADE_SOL = "TEST_PREP_SUBJECT_5TH_GRADE_SOL",
  TEST_PREP_SUBJECT_ACT = "TEST_PREP_SUBJECT_ACT",
  TEST_PREP_SUBJECT_ELEMENTARY_STANDARD_TESTING = "TEST_PREP_SUBJECT_ELEMENTARY_STANDARD_TESTING",
  TEST_PREP_SUBJECT_GED = "TEST_PREP_SUBJECT_GED",
  TEST_PREP_SUBJECT_GMAT = "TEST_PREP_SUBJECT_GMAT",
  TEST_PREP_SUBJECT_GRE = "TEST_PREP_SUBJECT_GRE",
  TEST_PREP_SUBJECT_MIDDLE_SCHOOL_SOL = "TEST_PREP_SUBJECT_MIDDLE_SCHOOL_SOL",
  TEST_PREP_SUBJECT_PRIVATE_SCHOOL_ENTRANCE = "TEST_PREP_SUBJECT_PRIVATE_SCHOOL_ENTRANCE",
  TEST_PREP_SUBJECT_PSAT_TEST = "TEST_PREP_SUBJECT_PSAT_TEST",
  TEST_PREP_SUBJECT_SAT_MATH = "TEST_PREP_SUBJECT_SAT_MATH",
  TEST_PREP_SUBJECT_SAT_VERBAL = "TEST_PREP_SUBJECT_SAT_VERBAL",
  TEST_PREP_SUBJECT_TOEFL = "TEST_PREP_SUBJECT_TOEFL",
}

/**
 * U.S. State Codes
 */
export enum UsStateCode {
  AK = "AK",
  AL = "AL",
  AR = "AR",
  AZ = "AZ",
  CA = "CA",
  CO = "CO",
  CT = "CT",
  DC = "DC",
  DE = "DE",
  FE = "FE",
  FL = "FL",
  GA = "GA",
  HI = "HI",
  IA = "IA",
  ID = "ID",
  IL = "IL",
  IN = "IN",
  KS = "KS",
  KY = "KY",
  LA = "LA",
  MA = "MA",
  MD = "MD",
  ME = "ME",
  MI = "MI",
  MN = "MN",
  MO = "MO",
  MS = "MS",
  MT = "MT",
  NC = "NC",
  ND = "ND",
  NE = "NE",
  NH = "NH",
  NJ = "NJ",
  NM = "NM",
  NV = "NV",
  NY = "NY",
  OH = "OH",
  OK = "OK",
  OR = "OR",
  PA = "PA",
  PR = "PR",
  RI = "RI",
  SC = "SC",
  SD = "SD",
  TN = "TN",
  TX = "TX",
  UT = "UT",
  VA = "VA",
  VT = "VT",
  WA = "WA",
  WI = "WI",
  WV = "WV",
  WY = "WY",
}

/**
 * Types of platform
 */
export enum UserDevicePlatformType {
  ANDROID = "ANDROID",
  IOS = "IOS",
  WEB = "WEB",
}

/**
 * Search for a specific location, primarily used in the location typeahead within the cost guide search
 */
export interface CostGuideLocationInput {
  location: string;
}

/**
 * Input values for Distance
 */
export interface DistanceInput {
  unit: DistanceUnit;
  value: number;
}

/**
 * Input type for review create short link request
 */
export interface ReviewShortLinkCreateInput {
  platform?: UserDevicePlatformType | null;
  providerCareType: ReviewInfoCareType;
  publicId: string;
  refer?: string | null;
}

/**
 * Segmentation hours filter input values
 */
export interface SEOHoursFilterInput {
  endTime?: globalScalarLocalTime | null;
  isOvernight?: boolean | null;
  startTime?: globalScalarLocalTime | null;
}

/**
 * Segmentation Filter input values
 */
export interface SEOSegmentationFilterInput {
  activityCategory?: string | null;
  activityDates?: globalScalarLocalDate[] | null;
  activityDaysOfWeek?: DayOfWeek[] | null;
  activityParticipationType?: ActivityParticipationType[] | null;
  activitySubcategory?: string | null;
  age?: string[] | null;
  availability?: AvailabilityList | null;
  certifiedNursingAssistant?: boolean | null;
  childCareAgeGroups?: ChildCareAgeGroups[] | null;
  childCareQualifications?: SEOChildCareQualifications[] | null;
  comfortableWithPets?: boolean | null;
  experienceWithAges?: string[] | null;
  experienceWithSpecialNeedsChildren?: boolean | null;
  hasCar?: boolean | null;
  homeHealthAid?: boolean | null;
  hourlyRate?: string | null;
  hours?: SEOHoursFilterInput | null;
  housekeepingSubServices?: HousekeepingService[] | null;
  jobType?: SEOSegmentationJobTypeFilter | null;
  jobsStartingSoon?: boolean | null;
  languages?: Language[] | null;
  newJobsOnly?: boolean | null;
  nonSmoker?: boolean | null;
  numberOfChildren?: number | null;
  radius?: DistanceInput | null;
  registeredNurse?: boolean | null;
  schedule?: string[] | null;
  seniorCareCprTrained?: boolean | null;
  seniorCareSubServices?: SEOSeniorCareSubServices[] | null;
  services?: string[] | null;
  yearsOfExperience?: number | null;
  zipcode?: string | null;
}

/**
 * Input for Zip Code Validation
 */
export interface SEOValidateZipCodeInput {
  zipcode: string;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
