import {
  ApolloClient,
  type MaybeMasked,
  NormalizedCacheObject,
  TypedDocumentNode,
  ApolloQueryResult,
  ApolloError,
} from '@apollo/client';

type ApolloClientQuery = <TVariable, TData>(
  client: ApolloClient<NormalizedCacheObject>,
  query: TypedDocumentNode<TData, TVariable>,
  variables: TVariable
) => Promise<
  | {
      queryResponse: ApolloQueryResult<MaybeMasked<TData>>;
      error: null;
    }
  | {
      queryResponse: null;
      error: ApolloError;
    }
>;

const apolloClientQuery: ApolloClientQuery = async <TVariable, TData>(
  client: ApolloClient<NormalizedCacheObject>,
  query: TypedDocumentNode<TData, TVariable>,
  variables: TVariable
) => {
  try {
    const queryResponse = await client.query({
      query,
      variables: {
        ...(variables as {}),
      },
      errorPolicy: 'all',
    });

    return { queryResponse, error: null };
  } catch (err) {
    return { queryResponse: null, error: err as ApolloError };
  }
};

export { apolloClientQuery };
