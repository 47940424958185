import { NextRouter } from 'next/router';
import { getSegmentUrl } from '@/utils/getServerSidePropsUtil';
import {
  COMMON_SEO_ENROLLMENT_PARAMS,
  SEO_SERVICE_DETAILS,
  SEO_SERVICE_SHORT_NAMES,
  ServiceIdForMember,
} from '@/constants';

interface GenerateEnrollmentUrlProps {
  router?: NextRouter;
  serviceIdForMember?: string;
  serviceId?: string;
  serviceShortName?: string;
  isMobile?: boolean;
  serviceProfileId?: string;
  directoryFirstExperience?: boolean;
}

const generateEnrollmentUrl = ({
  router,
  serviceIdForMember,
  serviceId: argServiceId = '',
  serviceShortName = '',
  serviceProfileId,
  directoryFirstExperience = false,
}: GenerateEnrollmentUrlProps) => {
  const serviceId = argServiceId !== '' ? argServiceId : SEO_SERVICE_SHORT_NAMES[serviceShortName];
  const serviceDetails = SEO_SERVICE_DETAILS[serviceId];
  const fromSeoPage = `fromSeoPage=${COMMON_SEO_ENROLLMENT_PARAMS.fromSeoPage}`;
  const serviceIdParam = `serviceId=${serviceId}`;
  let memberServiceId = serviceIdForMember ?? SEO_SERVICE_DETAILS[serviceId].serviceIdForMemberVal;
  const segmentUrl = router?.query.segment ? getSegmentUrl(router.query) : '';

  if (memberServiceId === memberServiceId.toUpperCase()) {
    memberServiceId = memberServiceId.toLowerCase();
  }

  const adultCareSegments = ['/adult-care', '/special-needs-adult-care', '/post-surgery-care'];

  if (serviceIdForMember === 'childCareLocation') {
    memberServiceId = 'dayCare';
  } else if (adultCareSegments.includes(segmentUrl)) {
    memberServiceId = 'adultCare';
  }

  const serviceIdForMemberParam = `serviceIdForMember=${memberServiceId}`;

  const source = `source=${COMMON_SEO_ENROLLMENT_PARAMS.source}`;
  const pageSource = `pageSource=${serviceDetails.pageSource}`;
  const visitedServiceProfileId = serviceProfileId
    ? `&visitedServiceProfileId=${serviceProfileId}`
    : '';
  const enrollmentQueryParams = `${fromSeoPage}&${serviceIdParam}&${serviceIdForMemberParam}&${source}&${pageSource}${visitedServiceProfileId}`;
  const enrollmentType =
    memberServiceId.slice(memberServiceId.length - 3) === 'Job' ? 'provider' : 'seeker';

  if (serviceIdForMember === ServiceIdForMember.CARE_GIGS) {
    return `/app/vhp/care-kind?${enrollmentQueryParams}`;
  }

  if (directoryFirstExperience && serviceIdForMember === ServiceIdForMember.CHILD_CARE_LOCATION) {
    return `/app/business-search?${enrollmentQueryParams}`;
  }

  if (serviceIdForMember === ServiceIdForMember.SPECIAL_NEEDS) {
    return `/app/enrollment/${enrollmentType}/cc?${enrollmentQueryParams}`;
  }

  // careGigsJob and specialNeedsJob to vertical-triage enrollment flow since they dont have a dedicated flow.
  // The multi-vertical flow will be used on "/jobs, /full-time-jobs, and /part-time-jobs" segmentation pages (these are under childCareJobs):
  if (
    serviceIdForMember === ServiceIdForMember.CARE_GIGS_JOB ||
    serviceIdForMember === ServiceIdForMember.SPECIAL_NEEDS_JOB ||
    segmentUrl === '/jobs' ||
    segmentUrl === '/full-time-jobs' ||
    segmentUrl === '/part-time-jobs'
  ) {
    return `/app/vhp/vertical-triage?memberType=sitter&${enrollmentQueryParams}`;
  }

  // Job search enrollment URL
  if (enrollmentType === 'provider') {
    return `/app/enrollment/provider/mv/${serviceId}?${enrollmentQueryParams}`;
  }

  return `/app/enrollment/${enrollmentType}/${serviceDetails.shortName}?${enrollmentQueryParams}`;
};

export default generateEnrollmentUrl;
